const uuidv4 = require('uuid/v4');

const ANON_ID = 'HZ_ANON_ID';
const token = uuidv4();

exports.onRouteUpdate = () => {
  if (process.env.NODE_ENV === 'production') {
    setTimeout(() => {
      let item = null;
      if (window.localStorage) {
        // eslint-disable-next-line no-negated-condition
        if (localStorage.getItem(ANON_ID) !== null) {
          item = localStorage.getItem(ANON_ID);
        } else {
          try {
            localStorage.setItem(ANON_ID, token);
            item = localStorage.getItem(ANON_ID);
          } catch (e) {
            return;
          }
        }
      }
      if (window.dataLayer) {
        try {
          window.dataLayer.push({ANON_ID: item});
        } catch (e) {
          // noop
        }
      }
    }, 50);
  }
};
